<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    @click="openRegisterDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Registeration'"
                  ></fab-button>
                  &nbsp;

                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <v-btn text @click="openFilterExpansion">
                      <v-icon>{{ openFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">{{ item.name }}</td>
                <td class="text-center">
                  {{ item.approved ? "APPROVED" : "PENDING" }}
                </td>
                <td class="text-center text-no-wrap">
                  <!-- <v-tooltip
                    top
                    v-if="item.approved && $moduleAccess('approve')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on">mdi-thumb-up</v-icon>
                    </template>
                    <span>Approved</span>
                  </v-tooltip> -->
                  <v-tooltip
                    top
                    v-if="item.approved && $moduleAccess('approve')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="green" icon>
                        <v-icon v-on="on">mdi-thumb-up</v-icon>
                      </v-btn>
                    </template>
                    <!-- <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on">mdi-thumb-up</v-icon>
                    </template> -->
                    <span>Approved</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-else-if="!item.approved && $moduleAccess('approve')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="amber"
                        icon
                        @click="openConfirmApproveDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-check-decagram</v-icon>
                      </v-btn>
                    </template>
                    <span>Approve</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!item.approved">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        @click="openDeleteDialog(item)"
                        icon
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- REGISTRATION DIALOG -->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="'Registration'"
      :color="'primary'"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'amber'"
      dark
      @closeDialog="closeRegistrationDialog()"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="4" class="py-10">
            <span class="font-weight-bold title">Client Details</span>
            <v-form ref="form">
              <v-text-field
                class="py-3"
                v-model="data.employee_first_name"
                label="First Name"
                outlined
                maxlength="20"
                dense
                required
                :rules="[rules.required, rules.allLetter]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.employee_last_name"
                label="Last Name"
                outlined
                maxlength="20"
                dense
                required
                :rules="[rules.required, rules.allLetter]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.employee_middle_name"
                label="Middle Name"
                outlined
                maxlength="20"
                dense
                required
                :rules="[rules.allLetter]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.employee_position"
                label="Designation"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.employee_contact_no"
                label="Contact No."
                type="number"
                outlined
                dense
                required
                :rules="[rules.required]"
              ></v-text-field>
              <!-- <v-text-field
                class="py-3"
                v-model="data.employee_fax_no"
                label="Fax No"
                type="number"
                outlined
                dense
              ></v-text-field> -->
              <v-text-field
                class="py-3"
                v-model="data.employee_email"
                label="Email"
                outlined
                dense
                required
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="4" class="py-10">
            <span class="font-weight-bold title">Business Details</span>
            <v-form ref="form1">
              <v-text-field
                class="py-3"
                v-model="data.business_name"
                label="Name"
                outlined
                maxlength="50"
                dense
                required
                :rules="[rules.required, rules.allLetter]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.business_type"
                label="Business Type"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.business_address"
                label="Address"
                outlined
                dense
                required
                :rules="[rules.required]"
              ></v-text-field>
              <!-- <v-text-field
                class="py-3"
                v-model="data.business_fax_no"
                label="Fax No"
                type="number"
                outlined
                dense
              ></v-text-field> -->
              <v-text-field
                class="py-3"
                v-model="data.business_contact_no"
                label="Contact No."
                type="number"
                outlined
                dense
                required
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                class="py-3"
                v-model="data.business_email"
                label="Email"
                outlined
                dense
                required
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="4" class="py-10">
            <v-form ref="form2">
              <span class="font-weight-bold title">Account Details</span>
              <v-text-field
                class="py-3"
                v-model="data.username"
                label="Username"
                outlined
                dense
                required
                :rules="[rules.required]"
              ></v-text-field>
              <v-btn color="primary" @click="openConfirmSubmitMethod()" block
                >Submit</v-btn
              >
              <!-- <v-btn color="primary" @click="openSuccessDialog()" block
                      >TEST</v-btn
                    > -->
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </full-width-dialog>
    <mini-dialog
      :dialog="saveApproveDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardText="'Confirm Client Approval?'"
      @closeDialog="closeSaveApproveDialog()"
      @confirmDialog="saveApprove()"
    ></mini-dialog>

    <!-------- Here is the Confirm Submit Dialog --------->

    <mini-dialog
      :dialog="confirmSubmitDialog"
      :max-width="'400'"
      :confirmSave="true"
      :cardTitle="cardTitle"
      :cardText="cardText"
      :loading="confirmLoading"
      @closeDialog="confirmSubmitDialog = false"
      @confirmDialog="saveRegistration()"
      :dialogType="dialogType"
    ></mini-dialog>

    <!---- Here is the Confirmation of Delete Company ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deleteCompany()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";

export default {
  data: () => {
    return {
      title: "Pre-Approve Clients",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Name", sortable: false },
        { text: "Operation Status", sortable: false, align: "center" },
        { text: "Action", sortable: false, align: "center" },
      ],
      rules: {
        required: (value) => !!value || "This field is required.",

        // limit: (value) => {
        //   value.length > 4 ? (value = value.substring(0, 4)) : "";
        //   return `Max Characters is 0 to 32`;
        // },

        allLetter: (value) => {
          const pattern = /[^a-zA-Z]$/;
          return (
            !pattern.test(value) || `Please input Alphabet characters only.`
          );
        },

        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      saveApproveDialog: false,
      confirmLoading: false,

      confirmSubmitDialog: false,
      openRegistrationDialog: false,

      fullWidthDialog: false,
      fullWidthDialogLoading: false,
      deleteDialog: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      try {
        let response = (await Services.getCompanies(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    openConfirmApproveDialog(item) {
      this.saveApproveDialog = true;
      this.data.id = item.id;
    },
    async saveApprove() {
      this.confirmLoading = true;

      try {
        const response = await Services.operationsApproval(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "Client has been approved!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async saveRegistration() {
      this.confirmLoading = true;

      const body = {
        company: {
          name: this.data.business_name,
          business_type: this.data.business_type,
          address: this.data.business_address,
          contact_no: this.data.business_contact_no,
          fax_no: this.data.business_fax_no,
          email: this.data.business_email,
        },
        employee: {
          first_name: this.data.employee_first_name,
          last_name: this.data.employee_last_name,
          middle_name: this.data.employee_middle_name,
          position: this.data.employee_position,
          contact_no: this.data.employee_contact_no,
          fax_no: this.data.employee_fax_no,
          email: this.data.employee_email,
        },
        user: {
          username: this.data.username,
        },
      };

      try {
        const response = await Services.Registration(body);

        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          this.closeAllDialog();
          await this.getData();

          setTimeout(() => {
            this.openSuccessDialog();
          }, 600);
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async deleteCompany() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteCompany(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    closeSaveApproveDialog() {
      this.saveApproveDialog = false;
    },
    async openRegisterDialog() {
      this.fullWidthDialogLoading = true;
      this.fullWidthDialog = true;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeRegistrationDialog() {
      this.fullWidthDialog = false;
    },
    openConfirmSubmitMethod() {
      this.confirmSubmitDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    closeAllDialog() {
      this.confirmSubmitDialog = false;
      this.closeSaveApproveDialog();
      this.closeRegistrationDialog();
      this.closeDeleteDialog();
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
  },
};
</script>

<style></style>
